@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@900&display=swap');


* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: "Poppins", sans-serif;
}

a,
li {
  list-style: none;
  text-decoration: none;
}

.hero_section {
  display: flex;
  padding-left: 100px;
  margin-top: 100px;
  padding-bottom: 100px;
  padding-top: 70px;
  z-index: -1;
  justify-content: space-between;
  margin-bottom: -150px;
}

img {
  width: 100%;
  height: auto;
}

.header_text {
  color: #178C49;
  font-weight: 700;
  font-size: 48px;
  line-height: 65px;
}

.paragraph,
.download_text {
  color: #001918;
  font-size: 18px;
  font-weight: 400;
  line-height: 36px;
}

.download_text {
  font-size: 13px;
  width: 65%;
  line-height: 22px;
  font-weight: 500;
  margin-top: 20px;
}

.hero_sect_image_container {
  z-index: -1;
  height: 700px;
}

.hero_sect_text_container {
  margin-top: 40px;
}

.hero_sect_text_container .paragraph {
  margin-top: 30px;
}

.download_sect {
  display: flex;
  align-items: center;
  gap: 20px;
  margin-top: 30px;
}

.download_sect img {
  height: 54px;
  width: 171px;
}

.landing_section_two {
  background-color: #178C49;
  padding: 50px 0;
  margin-top: -170px;
  z-index: 2;
  padding-top: 100px;
}

.landing_section_two>div {
  display: none;
}

.pin_tabs {
  justify-content: space-between;
  padding-left: 100px;
  gap: 30px;
}

.active {
  display: flex !important;
}

.pin_text_tab {
  width: 60%;
}

.pin_text_tab button {
  margin-top: 40px;
}

.pin_text_tab .header_text {
  margin: 20px 0;
}

.pin_img_tab {
  border-top-left-radius: 24px;
  border-bottom-left-radius: 24px;
  padding: 50px;
  background-color: #3BD07B;
  width: 40%;
}

.truck {
  height: 125px;
  width: 125px;
  background-color: #178C49;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.pin_img_tab img {
  height: 65px;
  width: 65px;
}

h2 {
  font-size: 38px;
  line-height: 50px;
  font-weight: 700;
  color: white;
  margin: 20px 0;
}

.pin_img_tab p,
.what_we_do p,
.footer_links p,
.footer_contact p,
.footer_links a {
  font-size: 16px;
  font-weight: 400;
  line-height: 32px;
  color: white;
}

.footer_links p,
.footer_contact p,
.footer_links a {
  width: 200px;
}

.what_we_do {
  padding-top: 100px;
}

.what_we_do>div {
  display: flex;
  justify-content: space-between;
  padding: 0 100px;
  gap: 20px;
}

.what_we_do>h2 {
  text-align: center;
  margin-bottom: 50px;
}

.what_we_do p,
.what_we_do h2 {
  color: black;
  width: 100%;
}

.indicator {
  background-color: #EEFFF5;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #178C49;
  font-weight: 500;
  font-size: 24px;
  height: 40px;
  width: 40px;
}

.what_image_container {
  width: 49%;
  height: 536px;
}

.what_hr {
  width: 2%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.hrs {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 10px;
}

.hrs>div {
  width: 1.35px;
  height: 10px;
  background-color: #3BD07B;
}

.what_text_container {
  width: 49%;
  margin-top: 100px;
}

.what_text_container button {
  margin-top: 50px;
}


.what_image_container h2 {
  margin-bottom: 20px;
}

.section_faq {
  border: 3px solid #001918;
  width: 85%;
  padding: 50px 30px;
  margin: auto;
  margin-top: 80px;
  border-radius: 24px;
  box-shadow: 5px 5px #00A99D;
}

.section_faq>div {
  display: flex;
  justify-content: space-between;
  gap: 30px;
}

.section_faq .what_image_container button {
  text-align: start;
  justify-content: flex-start;
  padding-left: 30px;
  margin-bottom: 30px;
  transition: all ease-in-out 400ms;
}

.section_faq .what_text_container {
  margin-top: 0;
}

.section_faq .what_text_container>div {
  border: 1px solid rgba(0, 25, 24, 0.1);
  padding: 30px;
  height: 350px;
  border-radius: 10px;
}

.section_faq .what_text_container p {
  font-weight: 400;
  font-size: 17px;
  line-height: 36px;
  color: #001918;
}

.what_text_container img {
  width: 36px;
  height: 24px;
  margin-bottom: 2px;
}

.section_faq .what_image_container {
  height: auto;
}

.get_started_section {
  background-color: #F8A01B;
  padding: 50px;
  width: 85%;
  margin: auto;
  margin-top: 100px;
  border-radius: 24px;
  border: 3px solid #001918;
  display: flex;
  justify-content: space-between;
  gap: 50px;
  position: absolute;
  inset: 0;
  height: 240px;
  top: -200px;
}

.get_started_section .download_sect {
  justify-content: flex-end;
}

.get_started_section>div {
  width: 50%;
}

footer {
  background-color: #178C49;
  margin-top: 200px;
  position: relative;
  padding: 0 100px;
  padding-top: 250px;
  z-index: 1;
  padding-bottom: 50px;
}

.footer_links {
  display: flex;
}

.footer_links_item_three {
  width: 35%;
  display: flex;
  flex-direction: column;
}

.footer_links_item_one {
  width: 40%;
}

.footer_links_item_two {
  width: 25%;
}

.footer_links_item_two p {
  margin-top: 15px;
}

.footer_links_item_three,
.footer_links_item_two {
  border-left: 1px solid rgba(255, 255, 255, 0.3);
  padding-left: 30px;
  padding-bottom: 100px;
}

.footer_links h3,
.footer_links img {
  font-size: 16px;
  line-height: 27px;
  font-weight: 500;
  color: white;
  margin-bottom: 20px;
}



.flex {
  display: flex;
  gap: 20px;
}

.footer_links_item_three svg {
  margin-top: 7px;
}

.footer_links_item_three>div:nth-child(2) {
  margin-bottom: 20px;
}

.footer_contact {
  padding: 20px 30px;
  background-color: #27AE61;
  border-radius: 27px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.footer_contact p {
  width: 50%;
}

.footer_icon_section {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 20px;
  width: 50%;
}

.footer_icon_section img {
  height: 40px;
  width: 40px;
}

.hero_text_container {
  align-items: center;
  justify-content: center !important;
  margin-bottom: 50px;
}

.mobile_view {
  display: none !important;
}

.mobile_list {
  display: none;
}

@media(max-width:650px) {

  .desktop_view {
    display: none;
  }

  .mobile_view {
    display: block !important;
  }


  .hero_text_container {
    margin-bottom: 0;
  }


  .header_text {
    font-size: 30px;
    line-height: 45.33px;
  }

  .paragraph {
    line-height: 22px;
    font-size: 14px;
  }

  .download_sect {
    width: 100%;
  }

  .download_text {
    width: 100%;
    margin-bottom: 30px;
  }

  .truck {
    width: 92px;
    height: 92px;
  }

  .truck img {
    width: 44px;
    height: 44px;
  }

  .download_sect img {
    width: 50%;
    height: 43.5px;
  }

  .hero_section {
    margin-bottom: 0px;
    flex-direction: column;
    padding: 0 20px;
    padding-bottom: 0;
    margin-top: 70px;
  }

  .hero_sect_image_container {
    height: auto;
    margin-bottom: -40px;
  }

  .landing_section_two {
    margin-top: 0;
  }

  .landing_section_two {
    padding: 20px;
    padding-right: 0;
  }



  .pin_text_tab button {
    margin: 20px 0;
  }

  .pin_tabs {
    padding-left: 0px;
  }

  .pin_text_tab {
    width: 100%;
    padding-right: 20px;
  }

  h2 {
    line-height: 40px;
    font-size: 24px;
  }

  .pin_img_tab {
    width: 100%;
    margin-bottom: 50px;
    padding: 40px 25px;
  }


  .active {
    flex-direction: column;
  }

  .desktop_view .what_we_do>div {
    flex-direction: column !important;
    padding: 20px;
  }

  .what_image_container,
  .what_text_container {
    width: 100%;
    margin-top: 0;
    height: auto;
  }

  .what_hr {
    display: none;
  }

  .section_faq {
    margin-top: 40px;
    padding: 20px 10px;
    width: 90%;
  }


  .section_faq>div {
    flex-direction: column;
  }

  .scrollble {
    overflow-y: scroll;
    -ms-overflow-style: none;
    scrollbar-width: none;
  }

  .scrollble::-webkit-scrollbar {
    display: none;
  }

  .section_faq .what_text_container>div {
    height: auto;
  }

  .section_faq .what_image_container button {
    margin-bottom: 0;
  }

  .section_faq .what_image_container {
    display: flex;
    width: 2100px;
    overflow: hidden;
  }

  .get_started_section {
    flex-direction: column;
    height: auto;
    position: static;
    padding: 20px;
    margin-top: -150px;
    width: 100%;
  }

  .get_started_section>div {
    width: 100%;
  }

  footer {
    padding: 20px;
  }

  .footer_links {
    flex-direction: column;
  }

  .footer_links_item_one,
  .footer_links_item_two,
  .footer_links_item_three {
    width: 100%;
  }

  .footer_contact {
    flex-direction: column;
    padding: 40px 10px;
  }

  .footer_contact p {
    width: 100%;
    text-align: center;
    margin-bottom: 15px;
  }

  .footer_icon_section {
    width: 100%;
    justify-content: center;
  }

  .footer_links_item_one {
    margin: 50px 0;
  }

  .footer_links_item_three,
  .footer_links_item_two {
    padding-bottom: 50px;
  }

  .footer_links_item_three>div {
    align-items: flex-start;
  }

  .footer_links_item_three svg {
    margin-top: 7px;
  }

  .mobile_view .what_hr {
    display: flex !important;
  }

  .mobile_list {
    display: flex;
    flex-direction: column;
    position: fixed;
    background-color: #27AE61;
    color: white;
    inset: 0;
    height: 100vh;
    align-items: flex-end;
    padding: 20px;
    transition: ease-in-out 300ms all;
    transform: translateX(200%);
  }

  .mobile_list a {
    padding: 15px;
    color: white;
    background-color: #178C49;
    border-radius: 10px;
    text-align: end;
  }


  .link_1 {
    width: 300px;
  }

  .link_2 {
    width: 250px;
  }

  .link_3 {
    width: 200px;
  }

  .link_4 {
    width: 150px;
  }

  .mobile_list img {
    height: 48px;
    width: 48px;
    margin-bottom: 20px;
  }


  footer .download_sect img {
    width: 50%;
    height: 40px;
  }


}