header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px 100px;
    background-color: rgba(255, 255, 255, 0.1);
    position: fixed;
    inset: 0;
    height: 100px;
    backdrop-filter: blur(2px);
    z-index: 999;
}

.header_logo img,
.footer_links img {
    height: 50px;
    width: 108px;
}

.nav_list {
    display: flex;
    align-items: center;
    gap: 40px;
    justify-content: space-between;
    border-radius: 8px;
    background-color: white;
    box-shadow: 1px 1.5px 1px 1px rgba(0, 25, 24, 0.05);
    padding: 15px 50px;
}

header a {
    font-family: "Poppins", sans-serif;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #001918;
}

.hamburger {
    display: none;
}

@media(max-width:650px) {
    .hamburger {
        display: block;
        height: 60px;
        width: 60px;
    }

    .open_menu {
        transform: translateX(0);
    }

    .nav_list {
        display: none;
    }

    header {
        padding: 20px;
        height: 70px;
    }

    .header_logo img {
        height: 48px;
    }

    header button {
        display: none !important;
    }
}