.btn {
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    font-weight: 500;
    font-family: "Poppins", sans-serif;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 1px 1.5px 1px 1px rgba(0, 25, 24, 0.05);
    border: none;
    border-radius: 10px;
    cursor: pointer;
}